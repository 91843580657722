<template>
  <template-item
    v-slot="{ object, rules }"
    :model="$promo"
    :show-actions="$auth.hasRight('promo__edit_information')"
    object-key-for-title="name"
    title-prepend="Акции /"
    :before-save="beforeItemSave"
    :after-save="afterSave"
  >
    <v-col class="pa-0">
      <v-row align="start" class="mt-3" no-gutters>
        <ImageUploader
          :image="object.image"
          aspect-ratio="0.5"
          class="mr-3"
          height="250"
          title="Старая"
          width="125"
          @input="uploadImage($event, 'image')"
        ></ImageUploader>
        <ImageUploader
          :image="object.square_image"
          class="mr-3"
          height="250"
          title="Маленькая"
          width="250"
          @input="uploadImage($event, 'square_image')"
        ></ImageUploader>
        <ImageUploader
          :image="object.wide_image"
          aspect-ratio="2"
          class="mr-3"
          height="250"
          title="В карточке акции"
          width="500"
          @input="uploadImage($event, 'wide_image')"
        ></ImageUploader>
      </v-row>
      <v-row no-gutters>
        <v-col class="pa-0" cols="6">
          <div class="text-h6 mt-3">Общая информация</div>
          <v-checkbox
            v-model="object.active"
            dense
            class="pa-0 ma-0 mb-3 mt-3"
            hide-details
            label="Активность"
          />
          <v-text-field
            v-model="object.name"
            class="mb-3 mt-5"
            dense
            hide-details
            label="Наименование"
            outlined
          />
          <v-text-field
            v-model="object.sorting"
            class="mb-3"
            dense
            hide-details
            label="Сортировка"
            outlined
            type="number"
          />
          <v-row no-gutters>
            <v-col>
              <date-picker
                clearable
                label="Дата начала"
                v-model="object.start_date"
                with-time
              ></date-picker>
            </v-col>
            <v-col class="pl-3">
              <date-picker
                clearable
                label="Дата окончания"
                v-model="object.end_date"
                with-time
              ></date-picker>
            </v-col>
          </v-row>
          <v-select
            class="mt-3"
            v-model="object.pickup_type"
            dense
            hide-details
            outlined
            :items="$promo.pickupTypes"
            item-text="label"
            item-value="value"
          ></v-select>
        </v-col>
        <v-col class="pa-0 pl-5" cols="6">
          <div class="text-h6 mt-3">Торговые точки</div>
          <v-row no-gutters align="center">
            <v-checkbox
              v-model="object.control_outlets"
              dense
              class="pa-0 ma-0 mb-3 mt-3"
              hide-details
              label="Контролировать список ТТ"
            />
            <v-spacer></v-spacer>
            <v-btn
              depressed
              class="text-none"
              color="primary"
              colo
              @click="outletsDialog = true"
            >
              Подобрать
            </v-btn>
          </v-row>
          <v-row no-gutters style="gap: 5px" class="mt-2">
            <v-chip small v-for="(el, index) in selectedOutlets" :key="index">
              {{ el.number }}. {{ el.address }}
            </v-chip>
          </v-row>
        </v-col>
      </v-row>
      <v-dialog v-model="outletsDialog" max-width="500px">
        <v-card class="pa-5">
          <v-row no-gutters align="center">
            <h2>Торговые точки</h2>
            <v-spacer></v-spacer>
            <search
              v-model="searchValue"
              :rounded="false"
              always
              class="ml-3"
            ></search>
          </v-row>
          <v-row no-gutters align="center" class="mt-2 mb-4">
            <v-btn
              depressed
              small
              class="text-none"
              color="primary"
              colo
              @click="object.outlets = $outlet.list.map((v) => v.id)"
            >
              Выбрать все
            </v-btn>
            <v-btn
              depressed
              small
              class="text-none ml-3"
              color="primary"
              colo
              @click="object.outlets = []"
            >
              Снять выбор
            </v-btn>
          </v-row>
          <div v-for="el in outletsBySearch" :key="el.id">
            <v-row no-gutters align="center" class="my-2">
              <v-checkbox
                class="pa-0 ma-0"
                :label="`${el.number}. ${el.address}`"
                hide-details
                :value="el.id"
                v-model="object.outlets"
              ></v-checkbox>
            </v-row>
          </div>
        </v-card>
      </v-dialog>

      <v-row align="center" class="mt-5 mb-3" no-gutters>
        <div class="text-h6">Блюда</div>
        <v-divider class="ml-3" />
      </v-row>

      <v-col
        v-for="(productGroup, index) in productGroups"
        :key="index"
        class="pa-0"
      >
        <div class="text-subtitle-1" v-text="productGroup.name" />
        <v-row>
          <v-col
            v-for="(product, i) in productGroup.products"
            :key="product.name"
            cols="6"
            md="3"
          >
            <v-card
              class="pa-1 cursor-pointer"
              height="100%"
              @click="toggleProduct(product)"
            >
              <v-row align="center" dense>
                <v-col cols="4">
                  <v-img
                    :src="product.image || '/images/not-found.png'"
                    aspect-ratio="1"
                    class="rounded"
                    contain
                  >
                    <div
                      v-if="isProductSelected(product)"
                      class="d-flex align-center justify-center"
                      style="
                        height: 100%;
                        background-color: rgba(0, 0, 0, 0.35);
                      "
                    >
                      <v-icon color="primary">mdi-check</v-icon>
                    </div>
                  </v-img>
                </v-col>
                <v-col cols="8">
                  <div
                    class="text-body-2"
                    style="white-space: pre-line"
                    v-text="product.name"
                  />
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-col>
  </template-item>
</template>

<script>
import imageUpload from "../../../vendor/imageUpload";
import ImageUploader from "@/admin/components/ImageUploader";
import Search from "@/components/template/Search.vue";
import search from "air-vue-model/search";

export default {
  name: "PromoItem",

  mixins: [imageUpload],
  components: {
    Search,
    ImageUploader,
  },

  data: () => {
    return {
      imagesToSetAfterCreateByField: {},
      outletsDialog: false,
      searchValue: null,
    };
  },

  computed: {
    outletsBySearch() {
      return search(
        this.searchValue,
        this.$outlet.list,
        (val) => `${val.number}${val.address}`
      );
    },
    productGroups() {
      return this.$productGroup.list;
    },

    promoProducts() {
      return this.$promo.item.products;
    },
    selectedOutlets() {
      return this.$outlet.list.filter((v) =>
        this.$promo.item.outlets.includes(v.id)
      );
    },
  },

  methods: {
    async uploadImage(file, field) {
      if (!this.$promo.item.id) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        this.imagesToSetAfterCreateByField[field] = file;
        reader.onload = () => {
          Object.assign(this.$promo.item, { [field]: reader.result });
        };
      } else {
        const formData = new FormData();
        formData.append("image", file);
        formData.append("type", field);
        const res = await this.$promo.sendPutSingle(
          "upload_image",
          this.$promo.item.id,
          formData
        );
        this.$promo.item[field] = res.url;
        this.$store.commit("setSnackbar", {
          color: "success",
          text: "Изображение сохранено",
        });
      }
    },
    isProductSelected(product) {
      return this.promoProducts.findIndex((e) => e.id === product.id) > -1;
    },

    toggleProduct(product) {
      if (this.isProductSelected(product)) {
        const index = this.promoProducts.findIndex((e) => e.id === product.id);
        this.promoProducts.splice(index, 1);
      } else {
        this.promoProducts.push(product);
      }
    },

    beforeItemSave(item) {
      const isBase64 = (v) =>
        typeof v === "string" && v.startsWith("data:") && v.includes("base64");
      const imageFields = ["image", "square_image", "wide_image"];
      for (const key of imageFields) {
        if (isBase64(item[key])) item[key] = null;
      }
    },
    afterSave(_) {
      for (const field of Object.keys(this.imagesToSetAfterCreateByField)) {
        void this.uploadImage(this.imagesToSetAfterCreateByField[field], field);
      }
    },
  },

  created() {
    this.$productGroup.loadList();
  },
};
</script>
